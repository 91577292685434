/** @jsx jsx */

import { jsx } from "@emotion/core"
import posed from "react-pose"

const PoseTile = posed.li({
  visible: {
    opacity: 1,
    x: 0,
  },
  invisible: {
    opacity: 0,
    x: -20,
  },
})

const Tile = ({ children }) => {
  return (
    <PoseTile css={{ listStyle: "none", margin: 0, opacity: 0 }}>
      {children}
    </PoseTile>
  )
}

export default Tile
