import React, { useEffect, useState } from "react"
import posed from "react-pose"

const PoseList = posed.ul({
  visible: {
    delayChildren: 200,
    staggerChildren: 100,
  },
})

const TileList = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => setIsVisible(true), [])

  return (
    <PoseList
      pose={isVisible ? "visible" : "invisible"}
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        gridGap: "1rem",
        margin: 0,
      }}
    >
      {children}
    </PoseList>
  )
}

export default TileList
